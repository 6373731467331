@tailwind base;
@tailwind components;
@tailwind utilities;

.fadeIn {
  animation: fadeIn 500ms linear both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
